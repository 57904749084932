<template>
  <div class="row">
    <div class="col-md-6 col-sm-12">
      <VueSignaturePad
        id="signature"
        width="100%"
        height="300px"
        ref="signPad"
        :options="options"
      />
      <div class="d-flex justify-content-between mt-3">
        <button type="button" class="rounded-0 btn btn-outline-secondary" @click="clean">
          <i class="be bi-trash" aria-hidden="true"></i>
        </button>
        <button type="button" class="rounded-0 btn btn-outline-dark" @click="change">
          <i class="be bi-pencil"></i>
        </button>
        <button type="button" class="rounded-0 btn btn-outline-primary" @click="save">
          <i class="be bi-check"></i>
        </button>
      </div>
    </div>
    <div class="col-md-6 col-sm-12">
      <h5 class="mb-2">Fonctionnement</h5>
      <p class="small text-muted mb-2">
        Vous générez votre signature ici qui sera apposée sur la convention:
      </p>
      <ul class="small text-muted pl-4 mb-0">
        <li>Maintenez votre souris tout en déplaçant</li>
        <li>En cas d'erreur, effacez puis recommencez</li>
        <li>Vous pouvez changer de couleur avec le bouton <em>Changer</em></li>
        <li>Une fois la signature est bien apposée, valider pour enregistrer</li>
        <li>Vous pouvez à tout moment remodifier en signant à nouveau</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Signature",
  props: { signature: String },
  data: () => ({ options: {}, loading: false, payload: {} }),
  methods: {
    onSubmit(value) {
      this.signature = value;
    },
    undo() {
      this.$refs.signPad.undoSignature();
    },
    fromDataURL(data, options = {}, callback) {
      return this.$refs.signPad.fromDataURL(data, options, callback);
    },
    save() {
      const { isEmpty, data } = this.$refs.signPad.saveSignature();
      // const { isEmpty, data } = this.$refs.signPad.saveSignature();
      if (!isEmpty) {
        this.onSubmit(data);
        this.$emit("sign", data);
      }
    },
    change() {
      this.options = {
        penColor: "#00f", // "#c0f"
      };
    },
    clean() {
      this.$refs.signPad.clearSignature();
    },
  },
};
</script>

<style scoped>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>
